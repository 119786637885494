import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#support",
        "aria-label": "support permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Support`}</h1>
    <p>{`Join our slack channel to gain direct access to our support and development team. Otherwise, contact us `}<a parentName="p" {...{
        "href": "mailto:support@medipass.io"
      }}>{`support@medipass.io`}</a>{`.`}</p>
    <p>{`The service is made available 24x365. However, Medicare/DVA have periodic scheduled maintenance windows which may impact claims outside of business hours. Services Australia service status can be checked at `}<a parentName="p" {...{
        "href": "https://www2.medicareaustralia.gov.au/pext/ECLIPSEMonitor/external/eclipse_status.jsp"
      }}>{`https://www2.medicareaustralia.gov.au/pext/ECLIPSEMonitor/external/eclipse`}{`_`}{`status.jsp`}</a></p>
    <p>{`Note that we observed several instances where outages are not correctly reported.`}</p>
    <p>{`Tyro Health service status can be checked at: `}<a parentName="p" {...{
        "href": "https://status.medipass.io/"
      }}>{`https://status.medipass.io`}</a>{` - reference API health check.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      